import {createContext,useEffect,useReducer} from 'react';

export const AuthContext=createContext();
const authReducer=(state,action)=>{
    switch (action.type){
        case 'LOGIN':
            return {user:action.payload,authIsReady:true}
            case "AUTH_IS_READY":
            return {user:action.payload,authIsReady:true}
        case 'LOGOUT':
            return {user:null,authIsReady:true}
        default:
            return state
    }
}
export const AuthContextProvider=({children})=>{
    const [state,dispatch]=useReducer(authReducer,{
        user:null,
        authIsReady:false
    })

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user_token'));
        console.log(user,"user");
        if(user){
            dispatch({type:'LOGIN',payload:user})
            dispatch({type:'AUTH_IS_READY',payload:user})
        }
        else{
            dispatch({type:'AUTH_IS_READY',payload:user})
        }
    }, [])

    console.log("AuthContext",state);

    return(
        <AuthContext.Provider value={{...state,dispatch}}>
            {children}
        </AuthContext.Provider>
    )
   
}
