import { usePost } from 'hooks/usePost';
import React, { useEffect, useState } from 'react'
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import {
    Button, Modal, ModalHeader, ModalBody, ModalFooter,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup
  } from "reactstrap";
export default function SpecialdatesPopup({modal,toggle}) {
    const [formData, setFormData] = useState({
        occasionName: '',
        toDate: '',
        fromDate: '',
      });
      const {postData,resData,postError,isPending} = usePost();
      const [isTemporarySlot,setIsTemporarySlot] = useState(false);
      const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
          ...formData,
          [name]: value
        });
      };
      const handleAddClick = () => {
        console.log(formData); 
        const payload = {
          "occasion_name": formData.occasionName,
          "from_date": formData.fromDate,
          "to_date": formData.toDate,
          "grade": "A"
        }
        postData(process.env.REACT_APP_NODE_SERVER+"/api/specialDates/create",payload,"POST")
    };
    useEffect(()=>{
      if(resData && resData.status ===200){
        NotificationManager.success('Exclusive date added successfully!', 'Success', 3000);
        toggle();
        setInterval(()=>{
          window.location.reload();
        },1000)
      }
      if(postError){
        NotificationManager.error(postError, 'Success', 3000);
        return;
      }
    },[resData,postError])
  return (
    <>
    <NotificationContainer/>
    <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Add Special Dates</ModalHeader>
        <ModalBody>
        <Form role="form">
            <div className="row">
      <FormGroup className="mb-3 col-sm-12">
        <InputGroup className="input-group-alternative">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="ni ni-bullet-list-67" />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            placeholder="Occasion Name"
            type="text"
            name="occasionName"
            value={formData.occasionName}
            onChange={handleInputChange}
          />
        </InputGroup>
      </FormGroup>
      </div>
      <div className="row">
      <FormGroup className="mb-3 col-sm-6">
        <InputGroup className="input-group-alternative">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
            <i className="ni ni-time-alarm" />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            placeholder="From Date"
            type="date"
            name="fromDate"
            value={formData.fromDate}
            onChange={handleInputChange}
          />
        </InputGroup>
      </FormGroup>
      <FormGroup className="mb-3 col-sm-6">
        <InputGroup className="input-group-alternative">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="ni ni-time-alarm" />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            placeholder="To Date"
            type="date"
            name="toDate"
            value={formData.toDate}
            onChange={handleInputChange}
            autoComplete="new-slotstart"
          />
        </InputGroup>
      </FormGroup>
      </div>
      {/* <div className='text-center'>
      <Button onClick={()=>setIsTemporarySlot(!isTemporarySlot)} className="my-4" color="primary" type="button">
          {isTemporarySlot ? "Cancel" : "Add a Temporary Slot"}
        </Button>
      </div> */}
    </Form>
        </ModalBody>
        <ModalFooter>
        {<Button className="my-4" onClick={handleAddClick} disabled={isPending} color="primary" type="button">
          {!isPending ? "Add" : "Adding..."}
        </Button>}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      </>  
  )
}
