import { useFetch } from 'hooks/useFetch';
import { usePost } from 'hooks/usePost';
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { Spinner,Button } from 'reactstrap';
import successImg from '../../../assets/img/theme/success.png';
import '../../../App.css'
export default function Success() {
    const {order_id,cashfree_order_id,booking_date} = useParams();
    const {data:orderTableData} = useFetch(`${process.env.REACT_APP_NODE_SERVER}/api/orders/getOrderDetails/${order_id}`);
    const {data:cashFreeOrderData} = useFetch(`${process.env.REACT_APP_NODE_SERVER}/api/payment/getAnOrder/${cashfree_order_id}`);
    const { postData, resData, postError } = usePost();
    const [isLoading,setIsLoading] = useState(true);

    useEffect(()=>{
        if(orderTableData && cashFreeOrderData){
            if(cashFreeOrderData.order_status === "PAID"){
                handleOrder();
            }
        }
    },[orderTableData,cashFreeOrderData])

    const handleOrder = () => {
        console.log(orderTableData);
        const payload = {
        name: orderTableData.order.name,
        email: orderTableData.order.email,
        contact: orderTableData.order.contact,
        user_id: orderTableData.order.user_id,
        slot_id: orderTableData.order.slot_id,
        adults_count: orderTableData.order.adults_count,
        children_count: orderTableData.order.children_count,
        joy_ride_count: orderTableData.order.joy_ride_count,
        joy_ride_children_count: orderTableData.order.joy_ride_children_count,
        below_2_year_count: orderTableData.order.below_2_year_count,
        order_id: cashFreeOrderData.order_id,
        payment_id: cashFreeOrderData.cf_order_id,
        booking_day: orderTableData.order.booking_day,
        booking_date: booking_date,
        is_special_day: orderTableData.order.is_special_day,
        grade: orderTableData.order.grade,
        archive: orderTableData.order.archive,
        single_adult_price: orderTableData.order.single_adult_price,
        single_child_price: orderTableData.order.single_child_price,
        total_cost: orderTableData.order.total_cost,
        amount_paid: Number(cashFreeOrderData.order_amount),
        amount_pending: orderTableData.order.amount_pending,
      };
        postData(`${process.env.REACT_APP_NODE_SERVER}/api/booking/create`,payload,"POST")
    }
    useEffect(()=>{
        if(resData && resData.status ===200){
            setIsLoading(false);
        }
        if(postError){
            alert("Error: "+postError);
        }
    },[resData,postError])
  return (
    <div className=' container pt-9' style={{height:"85vh"}}>
        {!isLoading && <div className="text-center">
            <img src={successImg} alt="success" className='pulse' width={120}/>
            <h1 className='mt-3'>Order Placed Successfully!</h1>
            <Link to="/spectator/index"><Button className="mt-3" color="primary">Back</Button></Link>
        </div>}
        {isLoading && <div className="text-center">
            <Spinner color="primary" style={{ height: '3rem',width: '3rem'}}></Spinner>
            <h4 className='mt-3'>Do not refresh!</h4>
        </div>}
    </div>
  )
}
