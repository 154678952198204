import { usePost } from 'hooks/usePost';
import React, { useEffect, useState } from 'react';
import {
  Card,
  CardHeader,
  Table,
  Row,
  Col,
  Input,
  CardFooter,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label
} from 'reactstrap';
import 'react-notifications/lib/notifications.css';
import {NotificationManager,NotificationContainer} from 'react-notifications';

export default function AllBookingsTable({ data, booking_date, handleChangeDate }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [isAddPeople,setIsAddpeople] = useState(false);
  const [below2years,setBelow2years] = useState(0);
  const [adults,setAdults] = useState(0);
  const [children,setChildren] = useState(0);
  const [additionPrice,setAdditionalPrice] = useState(0);
  const {postData,resData,postError,isPending} = usePost();
  const [joyRidePrice,setJoyRidePrice] = useState(600);
  const [joyRideCount,setJoyRideCount] = useState(0);
  const [isJoyRide,setIsJoyRide] = useState(false);
  const [joyRiderChildrenCount,setJoyRiderChildrenCount] = useState(0);
  const [paymentMode,setPaymentMode] = useState('');
  const [joyRidePriceChildren,setJoyRidePriceChildren] = useState(500);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const handlePriceRevision = () =>{
    setAdditionalPrice(adults*Number(selectedBooking.single_adult_price) + Number(children)*Number(selectedBooking.single_child_price)+Number(joyRideCount*joyRidePrice)+Number(joyRiderChildrenCount*joyRidePriceChildren))
  }
  useEffect(()=>{
    if(selectedBooking){
        handlePriceRevision();
    }
  },[adults,children,selectedBooking,joyRideCount,joyRiderChildrenCount])

  useEffect(()=>{
    if(!modalOpen){
      setIsAddpeople(false);
      setAdults(0);
      setChildren(0);
      setJoyRideCount(0);
      setJoyRiderChildrenCount(0);
    }
  },[modalOpen])

  useEffect(()=>{
    if(!isJoyRide){
      setJoyRideCount(0);
      setJoyRiderChildrenCount(0);
    }
  },[isJoyRide])

  useEffect(()=>{
    if(!isAddPeople){
      setAdults(0);
      setChildren(0);
    }
  },[isAddPeople])

  const openBookingDetails = (booking) => {
    setSelectedBooking(booking);
    toggleModal();
  };

  const handlePeopleChange = (e) =>{
    switch(e.target.name) {
        case "adults":
            setAdults(e.target.value);
            break;
        case "children":
            setChildren(e.target.value);
            break;
        case "below2year":
              setBelow2years(e.target.value);
              break;
        default:
            break;
    }
  }
  const handleBookingDetailsUpdate = () =>{
    console.log(selectedBooking,'console log');
    const payload = {
      "user_id": selectedBooking.user_id,
      "slot_id": selectedBooking.slot_id,
      "adults_count": Number(selectedBooking.adults_count)+Number(adults),
      "children_count": Number(selectedBooking.children_count)+Number(children),
      "below_2_year_count":Number(selectedBooking.below_2_year_count)+Number(below2years),
      "joy_ride_count" : Number(joyRideCount),
      "joy_ride_children_count": Number(joyRiderChildrenCount),
      "adult_joy_ride_total": Number(joyRideCount)*Number(joyRidePrice),
      "children_joy_ride_total":Number(joyRiderChildrenCount)*Number(joyRidePriceChildren),
      "order_id": selectedBooking.order_id,
      "payment_id": selectedBooking.payment_id,
      "single_adult_price": selectedBooking.single_adult_price,
      "single_child_price": selectedBooking.single_child_price,
      "total_cost": selectedBooking.total_cost+additionPrice,
      "amount_paid": selectedBooking.total_cost+additionPrice,
      "amount_pending": 0,
      "payment_mode":paymentMode,
      "booking_day": selectedBooking.booking_day,
      "name": selectedBooking.name,
      "email": selectedBooking.email,
      "contact": selectedBooking.contact,
      "is_special_day": selectedBooking.is_special_day,
      "grade": selectedBooking.grade,
      "archive": selectedBooking.archive
  }
  console.log(payload,'payload')
  postData(`${process.env.REACT_APP_NODE_SERVER}/api/booking/update/${selectedBooking.booking_id}`,payload,"PUT")
  }
  useEffect(()=>{
    if(resData && resData.status ===200){
      NotificationManager.success("Updated successfully!", '', 3000);
      setModalOpen(false);
      setInterval(()=>{
        window.location.reload();
      },1500)
    }
    if(postError){
      NotificationManager.error(postError, '', 3000);
    }
  },[resData,postError])
  return (
    <>
     <NotificationContainer />
      <Col className="mb-5 mb-xl-0" xl="12">
        <Card className="shadow">
          <CardHeader className="border-0">
            <Row className="align-items-center">
              <div className="col">
                <h3 className="mb-0">All Bookings</h3>
              </div>
              <div className="text-right">
                <Input type="date" value={booking_date} onChange={(e) => handleChangeDate(e.target.value)} />
              </div>
            </Row>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Contact Number</th>
                <th scope="col">Slot</th>
                <th scope="col">Big People</th>
                <th scope="col">Lil' Ones</th>
                <th scope="col">Amount Paid</th>
                <th scope="col">Pending Amount</th>
                <th scope="col">Total</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.bookings.map((each) => (
                  <tr key={each.booking_id}>
                    <th scope="row">{each.name}</th>
                    <td>{each.contact}</td>
                    <td>{each.slot_name}</td>
                    <td>{each.adults_count}</td>
                    <td>{each.children_count}</td>
                    <td className="text-success">₹{each.amount_paid}</td>
                    <td className="text-danger">₹{each.amount_pending}</td>
                    <td>₹{each.total_cost}</td>
                    <td>
                      <Button size="sm" color="primary" onClick={() => openBookingDetails(each)}>Details</Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
          <CardFooter className="py-4">
            {data && data.bookings.length === 0 && <p className="text-warning">No booking available!</p>}
          </CardFooter>
        </Card>
      </Col>

      {/* Modal */}
      <Modal className='booking-details-modal' isOpen={modalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Booking Details</ModalHeader>
        <ModalBody>
          {selectedBooking && (
            <div>
              <p>Name: {selectedBooking.name}</p>
              <p>Contact Number: {selectedBooking.contact}</p>
              <p>Email: {selectedBooking.email}</p>
              <p>Slot: {selectedBooking.slot_name}</p>
              <Button 
              className='mb-3' color='primary' size="sm" 
              onClick={()=>setIsAddpeople(!isAddPeople)}>{isAddPeople ? "Cancel" :"Add People"}</Button>
              <Button 
              className='mb-3' color='primary' size="sm" 
              onClick={()=>setIsJoyRide(!isJoyRide)}>{isJoyRide ? "Cancel" :"Add Joy Ride(s)"}</Button>
             {isAddPeople && <div className='row mb-4'>
                <div className="col-4">
                <Label for="numberOfAdults">
                  <small>Adults</small>
                </Label>
                  <Input type="number" value={adults} name="adults" onChange={(e)=>handlePeopleChange(e)} min="0" placeholder='Adult'/></div>
                <div className="col-4">
                <Label for="children">
                  <small>Children</small>
                </Label>
                  <Input type="number" value={children} name="children" onChange={(e)=>handlePeopleChange(e)} min="0" placeholder='Children'/></div>
                  <div className="col-4">
              <Label for="below2year">
                <small>Below 2 Year</small>
              </Label>
                <Input type="number" value={below2years} name="below2year" onChange={(e)=>handlePeopleChange(e)} min="0" placeholder='Below 2 Year'/></div>
              </div>
              }
              {isJoyRide && <div className='mb-4 row'>
                <div className="col-sm-6">
                <Label for="joyRide">
                  <small>No. Joy Ride(s)</small>
                </Label>
                  <Input type="number" value={joyRideCount} name="joyRide" onChange={(e)=>setJoyRideCount(e.target.value)} min="0"/>
                </div>
                <div className="col-sm-6">
                <Label for="joyRide">
                  <small>No. Joy Ride(s)</small>
                </Label>
                  <Input type="number" value={joyRiderChildrenCount} name="joyRide" onChange={(e)=>setJoyRiderChildrenCount(e.target.value)} min="0"/>
                </div>
                </div>}
                  <p>Summary:</p>
              {/* <table className="align-items-center table table-bordered" responsive>
                <thead>
                <tr>
                    <th scope="col">Adult</th>
                    <th scope="col">Children</th>
                    <th scope="col">Joy Ride</th>
                    <th scope="col">Paid</th>
                    <th scope="col">Pending</th>
                    <th scope="col">Total</th>
                </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{Number(selectedBooking.adults_count)+Number(adults)}</td>
                        <td>{Number(selectedBooking.children_count)+Number(children)}</td>
                        <td>{selectedBooking.joy_ride_count}</td>
                        <td className="text-success">₹{selectedBooking.amount_paid}</td>
                        <td className="text-danger">₹{Number(selectedBooking.amount_pending)+additionPrice}</td>
                        <td>₹{Number(selectedBooking.total_cost)+additionPrice}</td>
                    </tr>
                </tbody>
                </table> */}
                <table className="align-items-center table table-bordered" responsive>
                <thead>
                <tr>
                  <th/>
                    <th scope="col">No. of People</th>
                    <th scope="col">Per Person Cost</th>
                    <th scope="col">Total</th>
                </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Big People</td>
                    <td>{Number(selectedBooking.adults_count)+Number(adults)}</td>
                    <td>₹{Number(selectedBooking.single_adult_price)}</td>
                    <td>₹{(Number(selectedBooking.adults_count)+Number(adults))*Number(selectedBooking.single_adult_price)}</td>
                  </tr>
                  {(Number(selectedBooking.children_count)+Number(children))> 0 &&<tr>
                  <td>Lil' People</td>
                    <td>{Number(selectedBooking.children_count)+Number(children)}</td>
                    <td>₹{Number(selectedBooking.single_child_price)}</td>
                    <td>₹{(Number(selectedBooking.children_count)+Number(children))*Number(selectedBooking.single_child_price)}</td>
                  </tr>}
                  {(Number(selectedBooking.joy_ride_count)+Number(joyRideCount))>0 &&<tr>
                  <td>Big People Joy Ride</td>
                    <td>{Number(selectedBooking.joy_ride_count)+Number(joyRideCount)}</td>
                    <td>₹{Number(joyRidePrice)}</td>
                    <td>₹{(Number(selectedBooking.adult_joy_ride_total))+Number(joyRideCount)*Number(joyRidePrice)}</td>
                  </tr>}
                  {(Number(selectedBooking.joy_ride_count)+Number(joyRiderChildrenCount))>0 &&<tr>
                  <td>Lil' People Joy Ride</td>
                    <td>{Number(selectedBooking.joy_ride_children_count)+Number(joyRiderChildrenCount)}</td>
                    <td>₹{Number(joyRidePriceChildren)}</td>
                    <td>₹{(Number(selectedBooking.children_joy_ride_total))+Number(joyRiderChildrenCount)*Number(joyRidePriceChildren)}</td>
                  </tr>}
                  <tr>
                    <td colSpan={3}>Total</td>
                    <td>₹{Number(selectedBooking.total_cost)+additionPrice}</td>
                  </tr>
                  <tr>
                    <td colSpan={3}>Already Paid</td>
                    <td className="text-success">₹{selectedBooking.amount_paid}</td>
                  </tr>
                  <tr>
                    <td colSpan={3}>Balance Amount</td>
                    <td className="text-danger">₹{Number(selectedBooking.amount_pending)+additionPrice}</td>
                  </tr>
                </tbody>
                </table>
                <h4 className='text-danger'>Please collect the pending amount of ₹{Number(selectedBooking.amount_pending)+additionPrice}</h4>
                <div className='mb-3 mt-3'>
                  <Label>Select Payment Mode</Label>
                  <Input value={paymentMode} onChange={(e)=>setPaymentMode(e.target.value)} type='select'>
                    <option value=''>Select</option>
                    <option value='cash'>Cash</option>
                    <option vlaue='upi'>UPI</option>
                    <option vlaue='gpay'>GPAY</option>
                    <option vlaue='phonePe'>PhonePe</option>
                    <option value='Waiver'>Waiver</option>
                  </Input>
                </div>
                Alreay collected? {" "}
                {!isPending && <Button color="primary" size="sm" onClick={handleBookingDetailsUpdate}>Update</Button>}
                {isPending && <Button color="primary" size="sm" disabled>Updating</Button>}
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModal}>Close</Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
