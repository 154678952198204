import { useFetch } from 'hooks/useFetch'
import React, { useState } from 'react'
import {
    Spinner
  } from "reactstrap";
import AllBookingsTable from './components/AllBookingsTable';

export default function AllBookings() {
    const [booking_date,setBookingDate] = useState(new Date().toISOString().split('T')[0]);
    const {data,fetchIsPending} = useFetch(`${process.env.REACT_APP_NODE_SERVER}/api/booking/getAll?booking_date=${booking_date}`);

    const handleChangeDate = (selectedDate) =>{
      setBookingDate(selectedDate);
    }
  return (
    <div className='container pt-8'>
      
        {fetchIsPending && 
          <div className="text-center">
            <Spinner color="primary" style={{ height: '3rem',width: '3rem'}}></Spinner>
          </div>
        }

        {data && <AllBookingsTable data={data} booking_date={booking_date} handleChangeDate={handleChangeDate}/> }
    </div>
  )
}
