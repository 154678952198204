import React from 'react'
import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { useAuthContext } from 'hooks/useAuthContext';
import Spectator from 'layouts/Spectator';
export default function App() {
    const { user,authIsReady }=useAuthContext();
  return (
    <div>
        {authIsReady && <BrowserRouter>
            <Routes>
            {user && user.role === 1 && <Route path="*" element={<Navigate to="/admin/index" replace />} />}
            {user && user.role === 1 && <Route path="/admin/*" element={<AdminLayout />} />}
            {!user&& <Route path="/spectator/*" element={<Spectator />} />}
            {!user && <Route path="*" element={<Navigate to="/spectator/index" replace />} />}
            {!user && <Route path="/auth/*" element={<AuthLayout />} />}
            </Routes>
        </BrowserRouter>}
    </div>
  )
}
