  import React, { useEffect, useState } from "react";
  import Calendar from "react-calendar";
  import "react-calendar/dist/Calendar.css";
  import { Modal, ModalHeader, ModalBody } from "reactstrap";
  import "react-notifications/lib/notifications.css";
  import {
    NotificationContainer,
    NotificationManager,
  } from "react-notifications";
  import {
    Form,
    FormGroup,
    Input,
    InputGroup,
    CustomInput,
    Label,
    ModalFooter,
    Button,
    FormText,
    Spinner
  } from "reactstrap";
  import "./Booking.css";
  import { usePost } from "hooks/usePost";
  import ModalComponent from "../admin/components/ModalComponent";
  import { useFetch } from "hooks/useFetch";
  import { cashfree } from "cashfree/Util";

  export default function Booking() {
    const {data:dayData} = useFetch(`${process.env.REACT_APP_NODE_SERVER}/api/day/getAll`);
    const [modal, setModal] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [childBelowTwo, setChildBelowTwo] = useState(0);
    const [adultNum, setAdultNum] = useState(1);
    const [childrenNum, setChildrenNum] = useState(0);
    const [slotData, setSlotData] = useState(null);
    const [selectedSlot, setSelectedSlot] = useState("");
    const [totalPrice, setTotalPrice] = useState(0);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [contact, setContact] = useState("");
    const [agreeTerms, setAgreeTerms] = useState(false);
    const [orderTableId,setOrderTableId] = useState(null);
    const { postData, resData, postError, isPending } = usePost();
    const { postData:createOrder, resData:orderRes, postError:orderCreatingError, isPending:orderPending } = usePost();
    const [isSlotFull, setIsSlotFull] = useState(false);
    const [slotsLeft, setSlotsLeft] = useState(null);
    const [isLoading,setIsLoading] = useState(false);
    // const [sessionId,setSessionId] = useState('');
    const currentDate = new Date(); // Get the current date
    let currentDayOfWeek = currentDate.getDay(); // Get the current day of the week (0-6, where 0 is Sunday)
    if (currentDayOfWeek === 0) {
      // If it's Sunday, map it to 7
      currentDayOfWeek = 7;
    }
    
    const {
      postData: bookSlot,
      resData: bookingRes,
      postError: bookingError,
      isPending: bookingIsPending,
    } = usePost();
    const [isOpen, setIsOpen] = useState(false);

    const toggleModalComponent = () => {
      setIsOpen(!isOpen);
    };
    const isDisabledReactCalendar = ({ date, view }) => {
      // Disable Tuesdays and Wednesdays
      if (date.getDay() === 2 || date.getDay() === 3) {
        return true;
      }
      // Disable specific dates
      const disabledDates = [new Date("2024-03-10"), new Date("2024-03-18")];

      return disabledDates.some(
        (disabledDate) => date.toDateString() === disabledDate.toDateString()
      );
    };

    const toggleModal = () => {
      if (!modal) {
        setAdultNum(1);
        setChildrenNum(0);
        setChildBelowTwo(0);
        setSelectedSlot("");
        setTotalPrice(0);
      }
      setModal(!modal);
    };

    const handleCalendarClick = async (date) => {
      const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
      const dayOfWeekNumber = date.getDay() === 0 ? 7 : date.getDay();
      const dayOfWeekName = date.toLocaleDateString("en-US", { weekday: "long" });
      console.log(dayOfWeekName, dayOfWeekNumber);
      setSelectedDate({
        formattedDate: formattedDate,
        date: date,
        dayOfWeek: dayOfWeekNumber,
        dayOfWeekName: dayOfWeekName,
      });

      const response = await fetch(
        process.env.REACT_APP_NODE_SERVER +
          "/api/slotDetails/getSlotByDay/" +
          dayOfWeekNumber +
          "/" +
          formattedDate
      );
      console.log(response);
      try {
        setIsLoading(true);
        if (response.status !== 200) {
          throw new Error("Something went wrong!");
        }
        const data = await response.json();
        if (data.status === 200) {
          console.log(data);
          setSlotData(data);
          setIsLoading(false);
          toggleModal();
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };

    const handleRadioChange = (slot) => {
      console.log(slot);
      setSelectedSlot(slot);
    };
    const checkSlotAvailability = async () => {
      setSlotsLeft(null);
      const payload = {
        booking_date: selectedDate.formattedDate,
        max_adults: selectedSlot.max_adults,
        requestingAdults: Number(adultNum),
      };
      console.log(payload);
      postData(
        process.env.REACT_APP_NODE_SERVER +
          "/api/slotDetails/checkSlotAvailability",
        payload,
        "POST"
      );
    };

    useEffect(() => {
      if (resData && resData.status === 200) {
        if (!resData.isSlotFull) {
          setIsSlotFull(resData.isSlotFull);
          calcultatePrice();
        } else {
          setIsSlotFull(resData.isSlotFull);
          setSlotsLeft(resData.slotsRemaining);
        }
      }
      if (postError) {
        NotificationManager.error(postError, "Error", 3000);
      }
    }, [resData, postError]);

    useEffect(() => {
      if (selectedSlot !== "") {
        console.log(selectedSlot);
        setTotalPrice(0);
        setIsSlotFull(true);
        checkSlotAvailability();
      }
    }, [selectedSlot, adultNum, childrenNum]);

    const calcultatePrice = () => {
      let totalPrice =
        adultNum * Number(selectedSlot.adult_price) +
        childrenNum * Number(selectedSlot.children_price);
      console.log(totalPrice);
      setTotalPrice(totalPrice);
    };

    const handleFormSubmit = (e) => {
      e.preventDefault();
      // const payload = {
      //   name: name,
      //   email: email,
      //   contact: contact,
      //   user_id: Number(contact),
      //   slot_id: Number(selectedSlot.slot_id),
      //   adults_count: adultNum,
      //   children_count: childrenNum,
      //   joy_ride_count:0,
      //   joy_ride_children_count:0,
      //   below_2_year_count: Number(childBelowTwo),
      //   order_id: "ORD888",
      //   payment_id: "PAY888",
      //   booking_day: currentDayOfWeek,
      //   booking_date: selectedDate.formattedDate,
      //   is_special_day: 0,
      //   grade: "B",
      //   archive: 0,
      //   single_adult_price: selectedSlot.adult_price,
      //   single_child_price: selectedSlot.children_price,
      //   total_cost: totalPrice,
      //   amount_paid: Number(totalPrice) / 2,
      //   amount_pending: Number(totalPrice) / 2,
      // };
      const payload ={
        "user_id": Number(contact),
        "slot_id": Number(selectedSlot.slot_id),
        "adults_count": adultNum,
        "children_count": childrenNum,
        "below_2_year_count": Number(childBelowTwo),
        "joy_ride_count": 0,
        "joy_ride_children_count": 0,
        "adult_joy_ride_total": 0,
        "children_joy_ride_total": 0,
        "single_adult_price": selectedSlot.adult_price,
        "single_child_price": selectedSlot.children_price,
        "total_cost": totalPrice,
        "amount_pending": Number(totalPrice) / 2,
        "booking_day": currentDayOfWeek,
        "booking_date": selectedDate.formattedDate,
        "name": name,
        "email": email,
        "contact": contact,
        "is_special_day": false,
        "grade": 0,
        "archive": 0
    }
    
      if (
        selectedSlot === "" ||
        totalPrice === 0 ||
        name === "" ||
        email === "" ||
        contact === "" ||
        agreeTerms === false ||
        isSlotFull === true
      ) {
        NotificationManager.error(
          "Please fill the required details!",
          "Error",
          3000
        );
        return;
      }
      bookSlot(
        process.env.REACT_APP_NODE_SERVER + "/api/orders/create",
        payload,
        "POST"
      );
    };
    useEffect(() => {
      if (bookingRes && bookingRes.status === 200) {
        setOrderTableId(bookingRes.order_id);
        getSessionId();
      }
      if (bookingError) {
        NotificationManager.error(bookingError, "Error", 3000);
      }
    }, [bookingRes, bookingError]);

    const modalBody =()=>{
      return(
        <div>1. Once booked, guests will be allowed entry only up-to 45mins past their slot entry time and 
          any arrivals later than this will be treated as a cancellation with no refund.2. Weekend prices will 
          be applicable on public holidays and other special days</div>
      )
    } 
    const modalTitle =()=>{
      return(
        <div>Terms and Conditions</div>
      )
    } 
    const modalBody2 =()=>{
      return(
        <div>
        1.  If you request to cancel at 48 hours before your scheduled visit, you will receive a 100% refund.
        <br/><br/>
        2. For cancellations Less than 48 hours prior to the scheduled visit. No rescheduling or refunds shall be possible
        <br/><br/>
        3. For any cancellation requests, please contact us directly through WhatsApp at 9158004105 or email us at bookings@japalouppe.net, and make sure to provide your booking ID for faster assistance.</div>
      )
    } 
    const modalTitle2 =()=>{
      return(
        <div>Cancellation Policy</div>
      )
    } 

    const getSessionId = () => {
      if (
        selectedSlot === "" ||
        totalPrice === 0 ||
        name === "" ||
        email === "" ||
        contact === "" ||
        agreeTerms === false ||
        isSlotFull === true
      ) {
        NotificationManager.error(
          "Please fill the required details!",
          "Error",
          3000
        );
        return;
      }
    const payload = {
        "cf_order_id": "2181558012",
        "created_at": "2024-04-01T11:19:02+05:30",
        "customer_details": {
          "customer_id": "7112AAA812234",
          "customer_name": name,
          "customer_email": email,
          "customer_phone": contact,
        },
        "entity": "order",
        "order_amount": Number(totalPrice) / 2,
        "order_currency": "INR",
        "order_expiry_time": "2024-05-01T11:19:02+05:30",
        "order_meta": {
          "return_url": `${process.env.REACT_APP_NODE_SERVER}/spectator/index`,
          "notify_url": `${process.env.REACT_APP_NODE_SERVER}/spectator/index`,
          "payment_methods": "cc,dc,ccc,ppc,nb,upi,paypal,app"
        },
        "order_note": "Petting farm booking",
        "order_status": "ACTIVE",
      }
      createOrder(`${process.env.REACT_APP_NODE_SERVER}/api/payment/createAnOrder`,payload,"POST");
    }
    useEffect(()=>{
      if(orderRes && orderRes.status===200 ){
        console.log(orderRes);
        handlePayment(orderRes.responseData.payment_session_id,orderRes.responseData.order_id);
      }
      if(orderCreatingError){
        NotificationManager.error(orderCreatingError, '', 3000);
      }
    },[orderCreatingError,orderRes])
    const handlePayment = (sessionId,orderId) => {
      let checkoutOptions = {
        paymentSessionId: sessionId,
        returnUrl: `${process.env.REACT_APP_REACT_SERVER}/spectator/success/${orderTableId}/${orderId}/${selectedDate.formattedDate}`, 
      }
      cashfree.checkout(checkoutOptions).then(function(result){
        if(result.error){
          alert(result.error.message)
        }
        if(result.redirect){
          console.log("Redirection")
        }
      });
    }
    return (
      <div className="container pt-8 d-flex justify-content-center align-items-center">
        <NotificationContainer />
        <Calendar
          tileDisabled={({ date }) => {
            const dayOfWeek = date.getDay(); // Get the day of the week (0-6, where 0 is Sunday)
            // Adjust dayOfWeek to match your day_id (1-7, where 1 is Monday and 7 is Sunday)
            const adjustedDayOfWeek = dayOfWeek === 0 ? 7 : dayOfWeek;
            const daydata = dayData?.days.find((day) => day.day_id === adjustedDayOfWeek );
            // If dayData exists and is_weekly_off is 1, disable the tile
            return daydata && daydata.is_weekly_off === 1;
          }}
          onClickDay={handleCalendarClick}
          minDate={new Date()}
        />
        <Modal
          isOpen={modal}
          toggle={toggleModal}
          className="booking-details-modal"
        >
          <ModalHeader toggle={toggleModal}>
            <h3>
              Booking Date{" "}
              {selectedDate
                ? `${selectedDate.date.getDate().toString().padStart(2, "0")}/${(
                    selectedDate.date.getMonth() + 1
                  )
                    .toString()
                    .padStart(2, "0")}/${selectedDate.date.getFullYear()}`
                : ""}
            </h3>
          </ModalHeader>
          {slotData && slotData.slots.length===0 && <div className="ml-3 mb-5"><h2 className="text-warning">Sorry no slots are available!</h2>
            <p>Please select other date.</p>
          </div>}
          {slotData && slotData.slots.length>0 && <Form role="form" onSubmit={handleFormSubmit}>
            <ModalBody>
              <div className="row">
                <FormGroup className="mb-3 col-sm-4">
                  <Label for="numberOfAdults">
                    <small>Number of Big people</small>
                  </Label>
                  <InputGroup className="input-group-alternative">
                    <Input
                      placeholder="Number of adults"
                      type="number"
                      name="numberOfAdults"
                      value={adultNum}
                      onChange={(e) => setAdultNum(e.target.value)}
                      min="1"
                    />
                  </InputGroup>
                  <FormText>
                    10 years and above
                  </FormText>
                </FormGroup>
                <FormGroup className="mb-3 col-sm-4">
                  <Label for="numberOfAdults">
                    <small>Number of Lil People</small>
                  </Label>
                  <InputGroup className="input-group-alternative">
                    <Input
                      placeholder="Number of children"
                      type="number"
                      name="numberOfChildren"
                      value={childrenNum}
                      onChange={(e) => setChildrenNum(e.target.value)}
                      min="0"
                    />
                  </InputGroup>
                  <FormText>
                    2-10 years old
                  </FormText>
                </FormGroup>
                <FormGroup className="mb-3 col-sm-4">
                  <Label for="numberOfAdults">
                    <small>Below 2 years</small>
                  </Label>
                  <InputGroup className="input-group-alternative">
                    <Input
                      placeholder="Number of children below 2"
                      type="number"
                      name="numberOfChildren"
                      value={childBelowTwo}
                      onChange={(e) => setChildBelowTwo(e.target.value)}
                      min="0"
                    />
                  </InputGroup>
                  <FormText>
                    Free Entry
                  </FormText>
                </FormGroup>
                {<FormGroup className="mb-3 ml-3">
                  <Label for="exampleCheckbox">
                    <small>Select Slot</small>
                  </Label>
                  <div>
                    {slotData &&
                      slotData.slots.map((slot) => (
                        <CustomInput
                          key={slot.slot_id}
                          type="radio"
                          id={slot.slot_id}
                          name={slot.slot_name}
                          label={`${slot.from_time.slice(
                            0,
                            5
                          )} - ${slot.to_time.slice(0, 5)}`}
                          checked={Number(selectedSlot.slot_id) === slot.slot_id}
                          onChange={() => handleRadioChange(slot)}
                          inline
                        />
                      ))}
                  </div>
                </FormGroup>}
              </div>
              <div>
                
                  {isPending &&<div className="text-center mt-3"> <Spinner color="primary" style={{ height: '3rem',width: '3rem'}}></Spinner></div>}
                  
                {totalPrice > 0 && !isPending && (
                  <>
                    <p>Booking Summary:</p>
                    <div className="table-responsive">
                      <table className="align-items-center table table-bordered">
                        <thead>
                          <tr>
                            <th scope="col">People</th>
                            <th scope="col">No. of People</th>
                            <th scope="col">Cost Per Person</th>
                            <th scope="col">Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Big People</td>
                            <td>{adultNum}</td>
                            <td>₹{selectedSlot.adult_price}</td>
                            <td className="text-success">
                              ₹{Number(adultNum * selectedSlot.adult_price)}
                            </td>
                          </tr>
                          {childrenNum > 0 && (
                            <tr>
                              <td>Lil People</td>
                              <td>{childrenNum}</td>
                              <td>₹{selectedSlot.children_price}</td>
                              <td className="text-success">
                                ₹
                                {Number(
                                  childrenNum * selectedSlot.children_price
                                )}
                              </td>
                            </tr>
                          )}
                          <tr>
                            <td colSpan="3">Final Total</td>
                            <td className="text-success">
                              ₹
                              {Number(adultNum * selectedSlot.adult_price) +
                                Number(childrenNum * selectedSlot.children_price)}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <h4 className="text-success">
                      {/* {adultNum} Adult(s) x ₹{Number(selectedSlot.adult_price)} = ₹{Number(adultNum*selectedSlot.adult_price)} {childrenNum >0 &&<span>& {" "}
                    {childrenNum} Children x ₹{Number(selectedSlot.children_price)} = ₹{Number(childrenNum*selectedSlot.children_price)}</span>} <br/>
                    The total cost is ₹{totalPrice}<br/> */}
                      This will be a 50% (₹{totalPrice / 2}) advance payment. You
                      may pay the remaining 50% (₹{totalPrice / 2}) in cash or via
                      UPI when you arrive at the farm.
                    </h4>
                  </>
                )}
                {isSlotFull && (
                  <h4 className="text-danger">
                    {slotsLeft !== 0
                      ? `Only ${slotsLeft} adult slot(s) is available`
                      : `${slotsLeft} adult slot(s) is available`}
                  </h4>
                )}
              </div>
              {totalPrice > 0 && (
                <>
                  <hr />
                  <h4>Fill the following details</h4>
                  <div className="row">
                    <FormGroup className="mb-3 col-sm-6">
                      <Label for="name">
                        <small>Name</small>
                      </Label>
                      <InputGroup className="input-group-alternative">
                        <Input
                          placeholder="Your name"
                          type="text"
                          name="name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup className="mb-3 col-sm-6">
                      <Label for="email">
                        <small>Email</small>
                      </Label>
                      <InputGroup className="input-group-alternative">
                        <Input
                          placeholder="Your email"
                          type="email"
                          name="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup className="mb-3 col-sm-12">
                      <Label for="contact">
                        <small>Contact</small>
                      </Label>
                      <InputGroup className="input-group-alternative">
                        <Input
                          placeholder="Your contact number"
                          type="tel"
                          name="contact"
                          value={contact}
                          onChange={(e) => setContact(e.target.value)}
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup check className="ml-3 mt-3">
                      <Label check>
                        <Input
                          type="checkbox"
                          name="agreeTerms"
                          checked={agreeTerms}
                          onChange={(e) => setAgreeTerms(e.target.checked)}
                        />{" "}
                        I have read the
                      </Label>
                      {" "}<span className="text-danger" onClick={toggleModalComponent} style={{"cursor":"pointer"}}>terms and conditions</span> and 
                      {" "}<span className="text-danger" onClick={toggleModalComponent}style={{"cursor":"pointer"}}>cancellation policy</span>
                    </FormGroup>
                  
                  </div>
                </>
              )}
            </ModalBody>
            <ModalFooter>
              {!orderPending && (
                <Button
                  className="my-4"
                  color="primary"
                  disabled={!agreeTerms}
                  type="submit"
                >
                  Total Payable ₹{totalPrice / 2}
                </Button>
              )}
              {orderPending && (
                <Button className="my-4" color="primary" disabled type="button">
                  Booking...
                </Button>
              )}
              <Button color="secondary" onClick={toggleModal}>
                Cancel
              </Button>
            </ModalFooter>
          </Form>}
        </Modal>
        <ModalComponent isOpen={isOpen} toggleModalComponent={toggleModalComponent} body={modalBody()} title={modalTitle()}/>
        <ModalComponent isOpen={isOpen} toggleModalComponent={toggleModalComponent} body={modalBody2()} title={modalTitle2()}/>
      </div>
    );
  }
