import { useFetch } from 'hooks/useFetch';
import React, { useEffect, useState } from 'react';
import {
    Card,
    CardHeader,
    Table,
    Row,
    Col,
    Spinner,
    Button,
    FormGroup,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Input
} from "reactstrap";
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { usePost } from 'hooks/usePost';
import TemporarySlotModal from './TemporarySlotModal';

export default function SpecialDate() {
    const {data, fetchIsPending, fetchErr} = useFetch(process.env.REACT_APP_NODE_SERVER+"/api/specialDates/getAll");
    const [slotsData, setSlotsData] = useState(null);
    const [currentSlotId, setCurrentSlotId] = useState(null);
    const [cellName, setCellName] = useState(null);
    const [updatedSlot, setUpdatedSlot] = useState(null);
    const {postData,resData,postError,isPending}= usePost();
    const {postData:addTempSlot,resData:tempslotRes,postError:tempSlotErr,isPending:tempSlotPending}= usePost();
    const [isOpen, setIsOpen] = useState(false);
    const [selected,setSelected] = useState(null);
    const [formData, setFormData] = useState({
      slotName: '',
      slotOrder: '',
      slotStartFrom: '',
      slotTimeTo: '',
      slotDescription: '',
      slotImage: ''
    });

  const toggleModalComponent = () => {
    setIsOpen(!isOpen);
  };
  
  useEffect(()=>{
    if(tempslotRes && tempslotRes.status === 200){
      NotificationManager.success('Temporary slot added successfully!', '', 3000);
      isOpen(false);
      setInterval(()=>{
        window.location.reload();
      },1000)
    }
    if(tempSlotErr){
      NotificationManager.error(tempSlotErr, '', 3000);
      return;
    }
  },[tempslotRes,tempSlotErr])

    useEffect(() => {
        if(fetchErr) {
            NotificationManager.error(fetchErr, '', 3000);
        }
        if(data && data.status === 200) {
            setSlotsData(data.special_dates);
        }
    }, [fetchErr, data]);

    const handleDoubleClick = (slot, cellName) => {
        setCurrentSlotId(slot.slot_detail_id);
        setCellName(cellName);
    }

    const handleInputChange = (e, propertyName, currSlotId, currSpecialDateId) => {
      const newValue = e.target.value;
      console.log('New value:', newValue);
      console.log('Current slot ID:', currSlotId);
      console.log('Current special date ID:', currSpecialDateId);
  
      const updatedSlotsData = slotsData.map(specialDate => {
          if (specialDate.special_date_id === currSpecialDateId) {
              const updatedSlots = specialDate.slots.map(slot => {
                  if (slot.slot_detail_id === currSlotId) {
                      const updatedSlot = {
                          ...slot,
                          [propertyName]: newValue
                      };
                      setUpdatedSlot(updatedSlot); // Update the updatedSlot state with the new value
                      return updatedSlot;
                  } else {
                      return slot;
                  }
              });
  
              return {
                  ...specialDate,
                  slots: updatedSlots
              };
          } else {
              return specialDate;
          }
      });
  
      console.log('Updated slots data:', updatedSlotsData); // Check if updatedSlotsData contains the expected values
  
      setSlotsData(updatedSlotsData);
  }
    
    const handleInputBlur = () => {
      console.log(updatedSlot)
      if(!updatedSlot){
        setCurrentSlotId(null);
        return;
      }
      const payload = {
        "slot_id": updatedSlot.slot_id,
        "day_id":updatedSlot.day_id,
        "max_adults":updatedSlot.max_adults,
        "adult_price": updatedSlot.adult_price,
        "max_children":updatedSlot.max_children,
        "children_price": updatedSlot.children_price,
        "special_day_id":updatedSlot.special_day_id,
        "archive": updatedSlot.archive
      }
      postData(`${process.env.REACT_APP_NODE_SERVER}/api/slotdetails/update/${updatedSlot.slot_detail_id}`,payload,"PUT")
    }
    useEffect(()=>{
      if(resData && resData.status ===200){
        NotificationManager.success("Slot updated successfully!", '', 3000);
        setCurrentSlotId(null);
      }
      if(postError){
        NotificationManager.error(postError, '', 3000);
        return;
      }
    },[resData,postError])

    const handleFormFieldChange = (e) =>{
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value
      });
    }
    const handleClick = (each) =>{
      console.log(each)
      setSelected(each);
      toggleModalComponent();
    }
    const handleSubmit = (e) => {
      e.preventDefault();
      if (
        !formData.slotName ||
        !formData.slotStartFrom ||
        !formData.slotTimeTo ||
        !formData.slotOrder ||
        !formData.slotDescription
      ) {
        console.log('Please fill in all required fields');
        NotificationManager.error('Please fill in all required fields', '', 3000);
        return;
      }
      const payload = {
        "slot_name": formData.slotName,
        "from_time": formData.slotStartFrom,
        "to_time": formData.slotTimeTo,
        "slot_order": Number(formData.slotOrder),
        "slot_desc": formData.slotDescription,
        "slot_image": "morning_slot_image.jpg",
        "is_temporary": selected.special_date_id,
      }
      addTempSlot(`${process.env.REACT_APP_NODE_SERVER}/api/slot/addTemporarySlot`,payload,"POST")
    };
    const modalBody = () =>{
      return(
      <div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <FormGroup className="mb-3 col-sm-6">
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-bullet-list-67" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="Slot Name"
                type="text"
                name="slotName"
                onChange={handleFormFieldChange}
                autoComplete="new-slotname"
              />
            </InputGroup>
          </FormGroup>
          <FormGroup className="mb-3 col-sm-6">
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-bullet-list-67" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="Slot Order"
                type="text"
                name="slotOrder"
                onChange={handleFormFieldChange}
                autoComplete="new-slotorder"
              />
            </InputGroup>
          </FormGroup>
        </div>
        <div className="row">
          <FormGroup className="mb-3 col-sm-6">
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-time-alarm" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="Slot Start From"
                type="time"
                name="slotStartFrom"
                onChange={handleFormFieldChange}
                autoComplete="new-slotstart"
              />
            </InputGroup>
          </FormGroup>
          <FormGroup className="mb-3 col-sm-6">
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-time-alarm" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="Slot Time To"
                type="time"
                name="slotTimeTo"
                onChange={handleFormFieldChange}
                autoComplete="new-slottimeto"
              />
            </InputGroup>
          </FormGroup>
        </div>
        <FormGroup className="mb-3">
          <InputGroup className="input-group-alternative">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="ni ni-ruler-pencil" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              placeholder="Slot Description"
              type="text"
              name="slotDescription"
              onChange={handleFormFieldChange}
              autoComplete="new-slotdescription"
            />
          </InputGroup>
        </FormGroup>
        {/* <FormGroup className="mb-3">
          <InputGroup className="input-group-alternative">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="ni ni-image" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              placeholder="Slot Image"
              type="file"
              name="slotImage"
              onChange={handleFormFieldChange}
              autoComplete="new-slotimage"
            />
          </InputGroup>
        </FormGroup> */}
        <Button color="primary" type="submit" disabled={tempSlotPending}>{tempSlotPending ? "Adding...":"Submit"}</Button>
      </form>
    </div>
      )
    }
  
    return (
        <div>
            <NotificationContainer/>
            {fetchIsPending && <div className="text-center mt-5"><Spinner color="primary" style={{ height: '3rem', width: '3rem'}}></Spinner></div>}
            <Row className="mt-5">
                {data && slotsData && slotsData.map((each) => (
                    <Col key={each.special_date_id} className="mb-5 pb-4 mb-xl-0" xl="6">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                                <Row className="align-items-center">
                                    <div className="col">
                                        <h3 className="mb-0">{each.occasion_name}</h3>
                                        <h5>{new Date(each.from_date).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })} to {new Date(each.to_date).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })}</h5>
                                    </div>
                                    <div className="col text-right"> 
                                      <Button color='primary' size='sm' onClick={()=>handleClick(each)}>Add Temperory Slot</Button>
                                      <i className="ri-delete-bin-5-line text-danger" style={{fontSize:"20px",paddingTop:"20px"}} title="Delete"></i>

                                    </div>
                                </Row>
                            </CardHeader>
                            <Table className="align-items-center table-flush" responsive>
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col">Slot</th>
                                        <th scope="col">Big People</th>
                                        <th scope="col">Max</th>
                                        <th scope="col">Lil' Ones</th>
                                        <th scope="col">Max</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {each.slots.map((slot) => (
                                        <tr key={slot.slot_detail_id}>
                                            <th scope="row">{slot.slot_name}</th>
                                            <td onDoubleClick={() => handleDoubleClick(slot, 'adult_price')}>
                                                {(currentSlotId === slot.slot_detail_id && cellName === "adult_price") ? 
                                                    <input type="text" value={slot.adult_price} onChange={(e) => handleInputChange(e, 'adult_price',slot.slot_detail_id,each.special_date_id)}  onBlur={() => handleInputBlur(slot)}/>
                                                    : `₹${slot.adult_price}`
                                                }
                                            </td>
                                            <td onDoubleClick={() => handleDoubleClick(slot, 'max_adults')}>
                                                {(currentSlotId === slot.slot_detail_id && cellName === "max_adults") ? 
                                                    <input type="text" value={slot.max_adults} onChange={(e) => handleInputChange(e, 'max_adults',slot.slot_detail_id,each.special_date_id)} onBlur={() => handleInputBlur(slot)}/>
                                                    : `${slot.max_adults}`
                                                }
                                            </td>
                                            <td onDoubleClick={() => handleDoubleClick(slot, 'children_price')}>
                                                {(currentSlotId === slot.slot_detail_id && cellName === "children_price") ? 
                                                    <input type="text" value={slot.children_price} onChange={(e) => handleInputChange(e, 'children_price',slot.slot_detail_id,each.special_date_id)} onBlur={() => handleInputBlur(slot)}/>
                                                    : `₹${slot.children_price}`
                                                }
                                            </td>
                                            <td onDoubleClick={() => handleDoubleClick(slot, 'max_children')}>
                                                {(currentSlotId === slot.slot_detail_id && cellName === "max_children") ? 
                                                    <input type="text" value={slot.max_children} onChange={(e) => handleInputChange(e, 'max_children',slot.slot_detail_id,each.special_date_id)} onBlur={() => handleInputBlur(slot)}/>
                                                    : `${slot.max_children}`
                                                }
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Card>
                    </Col>
                ))}
            </Row>
            {selected && <TemporarySlotModal title={`Add Temporary Slot for ${selected.occasion_name}`} body={modalBody()} toggleModalComponent={toggleModalComponent} isOpen={isOpen}/>}
        </div>
    );
}
