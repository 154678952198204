import {useState} from "react"
export const usePost =()=>{
    const [postError,setPostError]=useState(null);
    const [isPending,setIsPending]=useState(false);
    const [resData,setResData]=useState(null);
    const postData=(url,postData,method)=>{
        setPostError(null);
        setIsPending(true);
        fetch(url,{
        method: method,
        headers:{
            'Content-Type':'application/json',
        },
        body:JSON.stringify(postData)
        }
        ).then(res=>res.json()).then((data)=>{
            try{
                if(data.status !== 200){
                    if(data.error){
                        throw new Error(data.error);
                    }
                    else{
                        throw new Error("Oops! something went wrong.")
                    }  
                }
                console.log(data)
                setResData(data);
                setIsPending(false);

            }catch(err){
                //console.log(err.message);
                setPostError(err.message);
                setIsPending(false);
            }
        })
    }

return{postData,resData,postError,isPending}
}