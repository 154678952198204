import { Modal as ReactstrapModal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

export default function TemporarySlotModal({title,body,toggleModalComponent,isOpen}) {
 

  return (
    <div>
      <ReactstrapModal isOpen={isOpen} toggle={toggleModalComponent}>
        <ModalHeader toggle={toggleModalComponent}>{title}</ModalHeader>
        <ModalBody>
          {body}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModalComponent}>Cancel</Button>
        </ModalFooter>
      </ReactstrapModal>
    </div>
  );
}
