import { useEffect, useState } from "react";

export const useFetch = (url) =>{
    const  [data, setData] = useState(null);
    const [fetchIsPending,setfetchIsPending] = useState(false);
    const [fetchErr,setFetchErr] = useState(null);

    useEffect(()=>{
        const abortController = new AbortController();
        const fetchData = async () => {
            setFetchErr(null);
            setfetchIsPending(true);
            const response = await fetch(url,{ signal : abortController.signal });
            try{
                if(response.status !== 200){
                    throw new Error("Oops! Something went wrong.");
                }
            const resData = await response.json();
            console.log(resData);
            setfetchIsPending(false);
            setData(resData);   
            }catch(error){
                if (error.name === 'AbortError') {
                    console.log('Fetch aborted');
                } else {
                    setFetchErr(error.message);
                    setfetchIsPending(false);
                }
            } 
        }
        fetchData();
        return () => {
            abortController.abort(); 
        };
    },[ url ])

    return{ data,fetchIsPending,fetchErr }
}