import React, { useEffect, useState, useRef } from "react";
import { Button } from "reactstrap";
import AddSlotPopup from "./components/AddSlotPopup";
import SpecialDate from "./components/SpecialDate";
import SpecialdatesPopup from "./components/SpecialdatesPopup";
import { useFetch } from "hooks/useFetch";
import { usePost } from "hooks/usePost";
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import Switch from "react-switch";

export default function PriceDashboard() {
  const [checkedRows, setCheckedRows] = useState({
    Monday: true,
    Tuesday: false,
    Wednesday: false,
    Thursday: true,
    Friday: true,
    Saturday: true,
    Sunday: true,
  });
  const [modal, setModal] = useState(false);
  const [specialDatesModal, setSpecialDatesModal] = useState(false);
  
  const { data } = useFetch(
    process.env.REACT_APP_NODE_SERVER + "/api/slotDetails/getAll"
  );
  const {postData,resData,postError,isPending} = usePost();
  const {postData:archiveSlot,resData:slotArchiveRes,postError:slotArchiveErr} = usePost();
  const {postData:updateWeeklyOff,resData:weeklyOffresData,postError:weeklyOffErr} = usePost();
  const toggle = () => setModal(!modal);
  const specialDatesToggle = () => setSpecialDatesModal(!specialDatesModal);
  const [allSlots, setAllSlots] = useState(null);
  const [editableCell, setEditableCell] = useState(null);
  const [editedSlot, setEditedSlot] = useState(null);
  const inputRef = useRef(null);
  const [cellName,setCellName] = useState('');

  useEffect(() => {
    const fetchAllSlots = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_NODE_SERVER + "/api/slot/getAll");
        if (!response.ok) {
          throw new Error('Failed to fetch slots');
        }
        const data = await response.json();
        setAllSlots(data);
      } catch (error) {
        console.error("Error fetching all slots:", error);
      }
    };
    fetchAllSlots();
  }, []);
  
  const handleCheckboxChange = (dayId,isWeeklyOff) => {
    console.log(dayId,isWeeklyOff);
    // setCheckedRows((prevCheckedRows) => ({
    //   ...prevCheckedRows,
    //   [day]: !prevCheckedRows[day],
    // }));
    if(isWeeklyOff === 1){
      updateWeeklyOff(`${process.env.REACT_APP_NODE_SERVER}/api/day/updateWeeklyOff/${dayId}/${0}`,{},"PUT")
    }
    if(isWeeklyOff === 0){
      updateWeeklyOff(`${process.env.REACT_APP_NODE_SERVER}/api/day/updateWeeklyOff/${dayId}/${1}`,{},"PUT")
    }
  };

  useEffect(()=>{
    if(weeklyOffresData && weeklyOffresData.status === 200 ){
      window.location.reload();
    }
    if(weeklyOffErr){
      NotificationManager.error(weeklyOffErr, '', 3000);
    }
  },[weeklyOffresData,weeklyOffErr])

  const handleCellDoubleClick = (slotId, slot, cellName) => {
    setEditableCell(slotId);
    setEditedSlot(slot);
    setCellName(cellName);
  };

  const handleSave = (newValue) => {
    console.log("Saving:", editedSlot, newValue, cellName);
    const updatedSlot = {
      "slot_id": editedSlot.slot_id,
      "day_id": editedSlot.day_id,
      "max_adults": cellName === "max_adults" ? Number(newValue) : editedSlot.max_adults,
      "adult_price": cellName === "adult_price" ? Number(newValue) : editedSlot.adult_price,
      "max_children": cellName === "max_children" ? Number(newValue) : editedSlot.max_children,
      "children_price": cellName === "children_price" ? Number(newValue) : editedSlot.children_price,
      "special_day_id": editedSlot.special_day_id,
      "archive": 0
    }
    console.log(updatedSlot);
     saveSlot(updatedSlot,editableCell);
  };

  const saveSlot = (updatedSlot,slotDetailId) =>{
    console.log(updatedSlot,slotDetailId);
    postData(process.env.REACT_APP_NODE_SERVER +"/api/slotDetails/update/"+slotDetailId,updatedSlot,"PUT");
  }
  useEffect(()=>{
    if(resData && resData.status === 200){
      setEditableCell(null);
      setCellName('');
      NotificationManager.success('Cell updated successfully!', 'Success', 3000);
      window.location.reload();
    }
    if(postError){
      NotificationManager.error(postError, 'Error', 3000);
    }
  },[resData,postError])
  const handleClickOutside = (event) => {
    if (inputRef.current && !inputRef.current.contains(event.target)) {
      if (editableCell) {
        const newValue = inputRef.current.value;
        handleSave(newValue);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [data, editableCell]);
  
  const handleChange =(slotId,currentArchiveVal)=> {
    console.log(slotId,currentArchiveVal)
    if(currentArchiveVal === 1){
      archiveSlot(`${process.env.REACT_APP_NODE_SERVER}/api/slot/archive-slot/${slotId}/${0}`,{},"PUT")
    }
    if(currentArchiveVal === 0){
      archiveSlot(`${process.env.REACT_APP_NODE_SERVER}/api/slot/archive-slot/${slotId}/${1}`,{},"PUT")
    }
  }
  useEffect(()=>{
    if(slotArchiveRes && slotArchiveRes.status===200){
      window.location.reload()
    }
    if(slotArchiveErr){
      NotificationManager.error(slotArchiveErr, '', 3000);
    }
  },[slotArchiveRes,slotArchiveErr])
  return (
    <div className="container pt-8">
      <NotificationContainer/>
      <Button className="mb-5" onClick={toggle} color="primary">
        Add Slot
      </Button>
      <Button className="mb-5" onClick={specialDatesToggle} color="primary">
        Add Exclusive dates
      </Button>
      {isPending && <p>Saving...</p>}
      <div className="table-responsive">
        <table className="table table-bordered">
          <thead>
            <tr className="table-active">
              <th scope="col">Day</th>
              {allSlots && allSlots.slots.map((each)=>(<th key={each.slot_id} scope="col" colSpan="4">
                {each.slot_name} {" "}<Switch onChange={()=>handleChange(each.slot_id,each.archive)} height={20} width={40} handleDiameter={20} checkedIcon={false} uncheckedIcon={false} checked={each.archive===0?true:false}/>
              </th>))}
            </tr>
          </thead>
          <tbody>
            <tr>
            <th></th>
              {allSlots && allSlots.slots.map((each)=>(<React.Fragment key={each.slot_id}>
              <th>Big People</th>
              <th>Max</th>
              <th>Lil' Ones</th>
              <th>Max</th>
              </React.Fragment>))}
            </tr>
            {data && allSlots &&
              Object.keys(data.slot_details).map((day) => (
                <tr
                  key={day}
                  style={{
                    backgroundColor: data.slot_details[day][allSlots.slots[0].slot_name].is_weekly_off === 0 ? "inherit" : "#D3D3D3",
                  }}
                >
                  <th scope="row">
                    <span className="ml-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={ data.slot_details[day][allSlots.slots[0].slot_name].is_weekly_off === 1 }
                        onChange={() => handleCheckboxChange(data.slot_details[day][allSlots.slots[0].slot_name].day_id,data.slot_details[day][allSlots.slots[0].slot_name].is_weekly_off)}
                      />
                    </span>{" "}
                    {day}
                  </th>
                  {Object.keys(data.slot_details[day]).map((slotName) => {
                    const slot = data.slot_details[day][slotName];
                    const slotId = slot.slot_detail_id;
                    return (
                      <React.Fragment key={slotId}>
                        <td 
                          onDoubleClick={() => handleCellDoubleClick(slotId, slot,"adult_price")}
                        >₹
                          {(editableCell === slotId && cellName === 'adult_price') ? (
                            <input
                              ref={inputRef}
                              type="text"
                              defaultValue={slot.adult_price}
                            />
                          ) : (
                            slot.adult_price
                          )}
                        </td>
                        <td onDoubleClick={() => handleCellDoubleClick(slotId, slot,"max_adults")}>
                        {(editableCell === slotId && cellName === 'max_adults') ? (
                            <input
                              ref={inputRef}
                              type="text"
                              defaultValue={slot.max_adults}
                            />
                          ) : (
                            slot.max_adults
                          )}
                          </td>
                        <td onDoubleClick={() => handleCellDoubleClick(slotId, slot,"children_price")}>₹
                        {(editableCell === slotId && cellName === 'children_price') ? (
                            <input
                              ref={inputRef}
                              type="text"
                              defaultValue={slot.children_price}
                            />
                          ) : (
                            slot.children_price
                          )}
                          </td>
                        <td onDoubleClick={() => handleCellDoubleClick(slotId, slot,"max_children")}>
                        {(editableCell === slotId && cellName === 'max_children') ? (
                            <input
                              ref={inputRef}
                              type="text"
                              defaultValue={slot.max_children}
                            />
                          ) : (
                            slot.max_children
                          )}
                         </td>
                      </React.Fragment>
                    );
                  })}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <SpecialDate />
      <AddSlotPopup modal={modal} toggle={toggle} />
      <SpecialdatesPopup
        modal={specialDatesModal}
        toggle={specialDatesToggle}
      />
    </div>
  );
}
