import { usePost } from 'hooks/usePost';
import React, { useEffect, useState } from 'react'
import {
    Button, Modal, ModalHeader, ModalBody, ModalFooter,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup
  } from "reactstrap";
  import 'react-notifications/lib/notifications.css';
  import {NotificationContainer, NotificationManager} from 'react-notifications';
export default function AddSlotPopup({modal,toggle}) {
    const [formData, setFormData] = useState({
        slotName: '',
        slotOrder: '',
        slotStartFrom: '',
        slotTimeTo: '',
        slotDescription: '',
        slotImage: ''
      });
      const {postData,resData,postError,isPending} = usePost();
      const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
          ...formData,
          [name]: value
        });
      };
      const handleAddSlot = () => {
        const payload = {
          "slot_name": formData.slotName,
          "from_time": formData.slotStartFrom,
          "to_time": formData.slotTimeTo,
          "slot_order": Number(formData.slotOrder),
          "slot_desc": formData.slotDescription,
          "slot_image": "evening_slot.jpg",
          "is_temporary" : 0,
          "archive": 0
      }
      console.log(payload)
        postData(process.env.REACT_APP_NODE_SERVER+'/api/slot/create',payload,"POST")
    };
    useEffect(()=>{
      if(resData && resData.status===200){
        NotificationManager.success('Slot added successully!', 'Success', 3000);
      }
      if(postError){
        NotificationManager.error(postError, 'Success', 3000);
        return
      }
    },[resData,postError])
  return (
    <>
    <NotificationContainer/>
    <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Add Slot</ModalHeader>
        <ModalBody>
        <Form role="form">
            <div className="row">
      <FormGroup className="mb-3 col-sm-6">
        <InputGroup className="input-group-alternative">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="ni ni-bullet-list-67" />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            placeholder="Slot Name"
            type="text"
            name="slotName"
            value={formData.slotName}
            onChange={handleInputChange}
            autoComplete="new-slotname"
          />
        </InputGroup>
      </FormGroup>
      <FormGroup className="mb-3 col-sm-6">
        <InputGroup className="input-group-alternative">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="ni ni-bullet-list-67" />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            placeholder="Slot Order"
            type="text"
            name="slotOrder"
            value={formData.slotOrder}
            onChange={handleInputChange}
            autoComplete="new-slotorder"
          />
        </InputGroup>
      </FormGroup>
      </div>
      <div className="row">
      <FormGroup className="mb-3 col-sm-6">
        <InputGroup className="input-group-alternative">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="ni ni-time-alarm" />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            placeholder="Slot Start From"
            type="time"
            name="slotStartFrom"
            value={formData.slotStartFrom}
            onChange={handleInputChange}
            autoComplete="new-slotstart"
          />
        </InputGroup>
      </FormGroup>
      <FormGroup className="mb-3 col-sm-6">
        <InputGroup className="input-group-alternative">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="ni ni-time-alarm" />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            placeholder="Slot Time To"
            type="time"
            name="slotTimeTo"
            value={formData.slotTimeTo}
            onChange={handleInputChange}
            autoComplete="new-slottimeto"
          />
        </InputGroup>
      </FormGroup>
      </div>
      <FormGroup className="mb-3">
        <InputGroup className="input-group-alternative">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="ni ni-ruler-pencil" />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            placeholder="Slot Description"
            type="text"
            name="slotDescription"
            value={formData.slotDescription}
            onChange={handleInputChange}
            autoComplete="new-slotdescription"
          />
        </InputGroup>
      </FormGroup>
      {/* <FormGroup className="mb-3">
        <InputGroup className="input-group-alternative">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="ni ni-image" />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            placeholder="Slot Image"
            type="text"
            name="slotImage"
            value={formData.slotImage}
            onChange={handleInputChange}
            autoComplete="new-slotimage"
          />
        </InputGroup>
      </FormGroup> */}
      <div className="text-center">
      </div>
    </Form>
        </ModalBody>
        <ModalFooter>
        <Button className="my-4" color="primary" onClick={handleAddSlot} disabled={isPending} type="button">
          {isPending ? "Adding...":"Add Slot"}
        </Button>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>  
  )
}
